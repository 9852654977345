<template>
  <div
    v-if="warehouse === null || warehouse === undefined"
    class="m-5 p-5 text-center bold-40"
  >
    {{ $t("manufacturingCollectList.noWarehouseSelected2") }}
  </div>
  <div
    v-else-if="selectedWarehouse?.type !== 2"
    class="m-5 p-5 text-center bold-40"
  >
    {{ $t("manufacturingCollectList.thisWarehouseNotAProductionType") }}
  </div>
  <div class="planner" v-else>
    <div class="mt-4 col-12" v-if="products === undefined || productsLoading">
      <div v-for="i in 3" :key="i" class="nature-white-box no-min-height mb-3">
        <div class="row mt-3 mb-4 ms-2">
          <div class="col">
            <h2>
              <Skeletor :width="Math.random() * 21 + 30 + '%'" />
            </h2>
            <div>
              <span>
                <Skeletor :width="Math.random() * 10 + 10 + '%'" />
              </span>
            </div>
          </div>
        </div>
        <Skeletor class="p-3 my-1" :width="Math.random() * 30 + 70 + '%'" />
        <Skeletor
          class="p-3 my-1"
          :width="Math.random() * 30 + 70 + '%'"
          v-if="Math.random() > 0.3"
        />
        <Skeletor
          class="p-3 my-1"
          :width="Math.random() * 30 + 70 + '%'"
          v-if="Math.random() > 0.6"
        />
        <Skeletor
          class="p-3 my-1"
          :width="Math.random() * 30 + 70 + '%'"
          v-if="Math.random() > 0.9"
        />
      </div>
    </div>
    <div v-else class="mt-4 col-12 row">
      <div class="col-md-4 col-lg-2">
        <nav
          class="left-nav"
          v-scroll-spy-link="{ selector: 'a', class: 'btn-primary' }"
          v-scroll-spy-active="{ selector: 'a div', class: 'btn-primary' }"
        >
          <div class="items">
            <a href="#" v-for="(d, i) in filteredPlanningItems" :key="i">
              <div class="btn w-100 btn-simple text-start">
                {{ $t(`manufacturingDesigner.waiting3`, { id: d.id }) }}
              </div>
            </a>
            <a href="#waiting"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("manufacturingDesigner.waitingForMaterial") }}
              </div></a
            >
            <a href="#ready"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("manufacturingDesigner.readyForProduction") }}
              </div></a
            >
            <a href="#production"
              ><div class="btn w-100 btn-simple text-start">
                {{ $t("manufacturingDesigner.underProduction") }}
              </div></a
            >
          </div>
        </nav>
      </div>
      <div class="col-md-8 col-lg-10" v-scroll-spy="{ offset: 80 }">
        <div
          id="planning"
          v-if="products.planning?.length === 0"
          class="nature-white-box no-min-height"
        >
          <h2>{{ $t("manufacturingDesigner.waiting2") }}</h2>
          <div class="m-5 p-5 text-center bold-40">
            {{ $t("manufacturingDailyList.thereIsNoPlannedProduction") }}
          </div>
        </div>
        <div
          id="planning"
          v-else
          class="nature-white-box no-min-height mt-4"
          v-for="(d, i) in filteredPlanningItems"
          :key="i"
        >
          <div class="row">
            <div class="col">
              <h2>
                {{
                  $t(`manufacturingDesigner.waiting`, {
                    id: d.id,
                    title: d.title,
                  })
                }}
              </h2>
            </div>
            <div class="col">
              <div v-if="q?.length > 0">
                {{ d.items?.reduce((sum, item) => sum + item.quantity, 0) }}
                item
              </div>
              <div v-else>
                {{ totalItemCount["planning_" + d.id] }}
                item
              </div>
              <div v-if="q?.length > 0">
                {{
                  formatTime(
                    d.items?.reduce((sum, item) => sum + item.time_left, 0)
                  )
                }}
                remaining
              </div>
              <div v-else>
                {{ formatTime(totalTimes["planning_" + d.id]) }} remaining
              </div>
            </div>
            <div class="col-auto text-end">
              <div class="d-inline-block me-2">
                <div class="d-flex no-wrap dropdown">
                  <button
                    class="btn btn-outline-primary dropdown-btn"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <BaseIcon name="download" />
                    <span>
                      {{ $t("topBar.export") }}
                    </span>
                  </button>
                  <div class="dropdown-menu">
                    <a
                      v-for="option in exportLineOptions"
                      :key="option.id"
                      class="dropdown-item"
                      :href="
                        appUrl +
                        `/warehouses/income/${d.id}/export/xlsx?page=1&line_option=${option.id}` +
                        token
                      "
                    >
                      <span class="regular-12 text-black">{{
                        option.name
                      }}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="d-inline-block me-2">
                <button
                  class="btn btn-outline-primary"
                  :class="{ 'loading-spin': setActiveBtn }"
                  :disabled="setActiveBtn"
                  v-on:click="startProduction(d.id)"
                  :title="$t(`incomingProducts.setFinished`)"
                >
                  <BaseIcon name="tick-circle" />
                  <span>{{ $t("manufacturingDesigner.startProduction") }}</span>
                </button>
              </div>
              <div class="d-inline-block" v-for="k in insideSteps" :key="k">
                <a
                  :href="`/production/collecting/${k}/${d.id}`"
                  target="_blank"
                  class="btn btn-outline-primary"
                >
                  <span
                    v-for="c in products.categories.filter(
                      (e) => e.manufacturing_material_step === k
                    )"
                    :key="c.id"
                  >
                    {{ c.name }}
                  </span>
                  {{ $t("manufacturingDesigner.collectingList") }}
                </a>
              </div>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="d.items"
            :show-del="false"
            :show-add="false"
            :show-edit="false"
            :show-tick="true"
            v-on:tick="
              (e) => {
                setActiveItem(e, i);
              }
            "
            v-on:update:field="valUpdated"
            type="products"
            :extra-value="{ productionModal: false }"
          />
        </div>
        <div v-for="i in steps" :key="i">
          <div
            id="waiting"
            v-if="(products.waiting[i - 1]?.items?.length ?? 0) === 0"
            class="nature-white-box no-min-height mt-4"
          >
            <div class="row">
              <div class="col">
                <h2>
                  {{
                    $t(`manufacturingDesigner.waitingForMaterialStep`, { i: i })
                  }}
                </h2>
                <div>
                  <span
                    class="me-2"
                    v-for="c in products.categories.filter(
                      (e) => e.manufacturing_material_step === i
                    )"
                    :key="c.id"
                  >
                    {{ c.name }}
                  </span>
                </div>
              </div>
              <div class="col-auto text-end">
                <a
                  :href="`/production/collecting/${i}`"
                  target="_blank"
                  class="btn btn-outline-primary"
                >
                  {{ $t("manufacturingDesigner.collectingList") }}
                </a>
              </div>
            </div>
            <div class="m-5 p-5 text-center bold-40">
              {{ $t("manufacturingDesigner.thereIsNoProductOnWaiting") }}
            </div>
          </div>
          <div id="waiting" v-else class="nature-white-box no-min-height mt-4">
            <div class="row">
              <div class="col">
                <h2>
                  {{
                    $t(`manufacturingDesigner.waitingForMaterialStep`, { i: i })
                  }}
                </h2>
                <div>
                  <span
                    class="me-2"
                    v-for="c in products.categories.filter(
                      (e) => e.manufacturing_material_step === i
                    )"
                    :key="c.id"
                  >
                    {{ c.name }}
                  </span>
                </div>
              </div>
              <div class="col">
                <div v-if="q?.length > 0">
                  {{
                    filteredWaitingItems[i - 1]?.items?.reduce(
                      (sum, item) => sum + item.quantity,
                      0
                    )
                  }}
                  item
                </div>
                <div v-else>
                  {{ totalItemCount["waiting_" + (i - 1)] }}
                  item
                </div>
                <div v-if="q?.length > 0">
                  {{
                    formatTime(
                      filteredWaitingItems[i - 1]?.items?.reduce(
                        (sum, item) => sum + item.time_left,
                        0
                      )
                    )
                  }}
                  remaining
                </div>
                <div v-else>
                  {{ formatTime(totalTimes["waiting_" + (i - 1)]) }}
                  remaining
                </div>
              </div>
              <div class="col-auto text-end">
                <router-link
                  :to="`/production/collecting/${i}`"
                  class="btn btn-outline-primary"
                >
                  {{ $t("manufacturingDesigner.collectingList") }}
                </router-link>
              </div>
            </div>
            <one-by-one-table
              v-if="filteredWaitingItems[i - 1] !== undefined"
              v-model:rows="rows"
              v-model:items="filteredWaitingItems[i - 1].items"
              :show-del="false"
              :show-add="false"
              :show-edit="false"
              type="products"
              v-on:update:field="valUpdated"
              :extra-value="{ productionModal: false }"
            />
          </div>
        </div>
        <div
          id="ready"
          v-if="products.ready?.length === 0"
          class="nature-white-box no-min-height mt-4"
        >
          <h2>{{ $t("manufacturingDesigner.readyForProduction") }}</h2>
          <div class="m-5 p-5 text-center bold-40">
            {{
              $t("manufacturingDesigner.thereIsNoProductOnReadyForProduction")
            }}
          </div>
        </div>
        <div id="ready" v-else class="nature-white-box no-min-height mt-4">
          <div class="row">
            <div class="col-auto">
              <h2>{{ $t("manufacturingDesigner.readyForProduction") }}</h2>
            </div>
            <div class="col">
              <div v-if="q?.length > 0">
                {{
                  filteredReadyItems?.reduce(
                    (sum, item) => sum + item.quantity,
                    0
                  )
                }}
                item
              </div>
              <div v-else>
                {{ totalItemCount["ready"] }}
                item
              </div>
              <div v-if="q?.length > 0">
                {{
                  formatTime(
                    filteredReadyItems?.reduce(
                      (sum, item) => sum + item.time_left,
                      0
                    )
                  )
                }}
                remaining
              </div>
              <div v-else>
                {{ formatTime(totalTimes["ready"]) }}
                remaining
              </div>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="filteredReadyItems"
            :show-del="false"
            :show-add="false"
            :show-edit="false"
            :show-tick="true"
            v-on:tick="startItemProduction"
            v-on:update:field="valUpdated"
            type="products"
            :extra-value="{ productionModal: false }"
          />
        </div>
        <div
          id="production"
          v-if="products.production?.length === 0"
          class="nature-white-box no-min-height mt-4"
        >
          <h2>{{ $t("manufacturingDesigner.underProduction") }}</h2>
          <div class="m-5 p-5 text-center bold-40">
            {{ $t("manufacturingDesigner.thereIsNoProductOnProduction") }}
          </div>
        </div>
        <div id="production" v-else class="nature-white-box no-min-height mt-4">
          <div class="row">
            <div class="col-auto">
              <h2>{{ $t("manufacturingDesigner.underProduction") }}</h2>
            </div>
            <div class="col">
              <div v-if="q?.length > 0">
                {{
                  filteredProductionItems?.reduce(
                    (sum, item) => sum + item.quantity,
                    0
                  )
                }}
                item
              </div>
              <div v-else>
                {{ totalItemCount["production"] }}
                item
              </div>
              <div v-if="q?.length > 0">
                {{
                  formatTime(
                    filteredProductionItems?.reduce(
                      (sum, item) => sum + item.time_left,
                      0
                    )
                  )
                }}
                remaining
              </div>
              <div v-else>
                {{ formatTime(totalTimes["production"]) }}
                remaining
              </div>
            </div>
          </div>
          <one-by-one-table
            v-model:rows="rows"
            v-model:items="filteredProductionItems"
            :show-del="true"
            :show-add="false"
            :show-edit="false"
            v-on:update:field="valUpdated"
            v-on:delete="deleteItem"
            type="products"
            :extra-value="{ productionModal: false }"
          />
        </div>
      </div>
    </div>
    <Modal
      ref="removeProductionModal"
      :title="$t(`manufacturingDailyList.doYouWantToRemoveItemsFromProduction`)"
    >
      <ul v-if="removeItem">
        <li>
          {{
            $t(`manufacturingDailyList.itemWillBeRemovedFromProduction`, {
              serial: removeItem.serial,
            })
          }}
        </li>
        <li>
          {{
            $t(
              "manufacturingDesigner.theRemainingMaterialsWillSetBackToTheWarehouse"
            )
          }}
        </li>
        <li>{{ $t("manufacturingDesigner.youCantUndoThisAction3") }}</li>
      </ul>
      <p>{{ $t("incomingProducts.areYouSureYouWantToDoThis") }}</p>
      <div>
        <button
          type="button"
          class="btn btn-primary"
          v-on:click="removeItemFromPoruction"
          :class="{ 'loading-spin': removeBtn }"
          :disabled="removeBtn"
        >
          <span>{{ $t("manufacturingDailyList.removeFromProduction") }}</span>
        </button>
      </div>
    </Modal>
    <ManufacturingModal
      v-if="selectedProduct"
      ref="productionModal"
      :steps="selectedProduct.manufacturing?.steps"
      :id="selectedProduct.manufacturing?.item_id"
      :warehouseOperationId="
        selectedProduct.manufacturing?.warehouse_operation_id
      "
      :name="selectedProduct.manufacturing?.title"
      v-model:total="selectedProduct.manufacturing.total"
      v-model:done="selectedProduct.manufacturing.done"
      :has-more-next="hasMoreNext"
      :has-more-prev="hasMorePrev"
      :image="selectedProduct?.image"
      :color="selectedProduct?.color_hex"
    />
  </div>
</template>
<script>
import http from "@/modules/http";
import { defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import date from "@/modules/date";
import OneByOneTable from "../../components/lists/OneByOneTable";
import store from "@/store";
import BaseIcon from "../../components/icons/BaseIcon.vue";
import Modal from "@/components/modals/Modal";
import ManufacturingModal from "../../components/modals/ManufacturingModal.vue";
import tenant from "../../modules/tenant";

export default {
  name: "ManufacturingDesigner",
  components: { ManufacturingModal, Modal, BaseIcon, OneByOneTable },
  data() {
    return {
      store: useStore(),
      rows: [
        {
          name: "image",
          key: "image",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "serial",
          key: "serial",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Serial")
          ),
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Sourcing",
          key: "warehouse_operation_id",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Production",
          key: "manufacturing",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Production")
          ),
          type: "object",
          show: true,
          editable: false,
        },
        {
          name: "",
          key: "print",
          type: "object",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/ItemOperationPrints")
          ),
          show: true,
          editable: false,
        },
        {
          name: "Color",
          key: "caracteristics_1",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Size",
          key: "caracteristics_2",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "quantity",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Total stock",
          key: "total_stock",
          type: "number",
          show: true,
          editable: false,
        },
        {
          name: "Start time",
          key: "start_date",
          type: "date",
          show: true,
          editable: true,
        },
        {
          name: "Deadline",
          key: "deadline_date",
          type: "date",
          show: true,
          editable: true,
        },
        {
          name: "Premier date",
          key: "income_deadline_date",
          type: "date",
          show: true,
          editable: false,
        },
        {
          name: "",
          key: "locked",
          type: "object",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/Lock")
          ),
          show: true,
          editable: false,
        },
        {
          name: "",
          key: "has_all_material",
          type: "object",
          component: defineAsyncComponent(() =>
            import("../../components/lists/listItems/HasAllMaterial")
          ),
          show: true,
          editable: false,
          extra_row_key: "variant_id",
        },
      ],
      totalTimes: {},
      totalItemCount: {},
      exportLineOptions: [],
      setActiveBtn: false,
      products: undefined,
      productsLoading: false,
      productsById: {},
      maxSteps: 0,
      steps: [],
      insideSteps: [],
      removeBtn: false,
      removeItem: null,
      selectedProduct: undefined,
      hasMoreNext: false,
      hasMorePrev: false,
    };
  },
  computed: {
    appUrl() {
      return tenant.getApiBaseUrl();
    },
    token() {
      return `&_token=${this.store.state.token}`;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    selectedWarehouse() {
      return this.warehouses.find((e) => e.id === this.warehouse);
    },
    mediaServer() {
      return process.env.VUE_APP_MEDIA_URL;
    },
    filterPage() {
      return this.store.state.filterPage;
    },
    filters() {
      return this.store.state.filters?.designer;
    },
    q() {
      return store.state.topBar.q;
    },
    filteredProductionItems() {
      if (
        (this.q === null || this.q.length < 3) &&
        Object.keys(this.filters)?.length < 1
      ) {
        return this.products.production;
      }
      return Object.values(this.filterItems(this.products.production));
    },
    filteredReadyItems() {
      if (
        (this.q === null || this.q.length < 3) &&
        Object.keys(this.filters)?.length < 1
      ) {
        return this.products.ready;
      }
      return Object.values(this.filterItems(this.products.ready));
    },
    filteredPlanningItems() {
      if (
        (this.q === null || this.q.length < 3) &&
        Object.keys(this.filters)?.length < 1
      ) {
        return this.products.planning;
      }
      let data = [];
      for (const e in this.products.planning) {
        data.push({
          id: this.products.planning[e].id,
          title: this.products.planning[e].title,
          items: Object.values(
            this.filterItems(this.products.planning[e].items)
          ),
        });
      }
      return Object.values(data.filter((e) => e.items.length ?? 0 > 0));
    },
    filteredWaitingItems() {
      if (
        (this.q === null || this.q.length < 3) &&
        Object.keys(this.filters)?.length < 1
      ) {
        return this.products.waiting;
      }
      let data = {};
      for (const e in this.products.waiting) {
        if ((this.products.waiting[e]?.items?.length ?? 0) < 1) {
          continue;
        }
        let items = this.filterItems(this.products.waiting[e].items);
        if (items === undefined || (items?.length ?? 0) < 1) {
          continue;
        }
        if (data[e] === undefined) {
          data[e] = [];
        }
        data[e].push({
          id: this.products.waiting[e].id,
          title: this.products.waiting[e].title,
          items: Object.values(items),
        });
      }
      return data;
    },
  },
  watch: {
    warehouses() {
      this.loadData();
    },
    warehouse() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    window.apps.subscribe(
      "manufacturingModal:show",
      (id, warehouseOperationId) => {
        this.openManufacturingModal(id, warehouseOperationId);
      }
    );
    window.apps.subscribe(
      "manufacturingModal:back",
      (id, warehouseOperationId) => {
        this.openManufacturingModal(id, warehouseOperationId, -1);
      }
    );
    window.apps.subscribe(
      "manufacturingModal:next",
      (id, warehouseOperationId) => {
        this.openManufacturingModal(id, warehouseOperationId, 1);
      }
    );
  },
  methods: {
    openManufacturingModal(id, warehouseOperationId, indexChange = 0) {
      const _this = this;
      let i = this.filteredReadyItems.findIndex(
        (e) =>
          e.item_id === id && e.warehouse_operation_id === warehouseOperationId
      );
      if (i > -1) {
        this.selectedProduct = this.filteredReadyItems[i + indexChange];
        this.hasMorePrev = i + indexChange > 0;
        this.hasMoreNext = i + indexChange < this.filteredReadyItems.length - 1;

        setTimeout(() => _this.$refs.productionModal.showModal(), 100);
        return;
      }
      i = this.filteredProductionItems.findIndex(
        (e) =>
          e.item_id === id && e.warehouse_operation_id === warehouseOperationId
      );
      if (i > -1) {
        this.selectedProduct = this.filteredProductionItems[i + indexChange];
        this.hasMorePrev = i + indexChange > 0;
        this.hasMoreNext =
          i + indexChange < this.filteredProductionItems.length - 1;

        setTimeout(() => _this.$refs.productionModal.showModal(), 100);
        return;
      }
      for (const element of Object.values(this.filteredWaitingItems)) {
        let i = element.items.findIndex(
          (e) =>
            e.item_id === id &&
            e.warehouse_operation_id === warehouseOperationId
        );
        if (i > -1) {
          this.selectedProduct = element.items[i + indexChange];
          this.hasMorePrev = i + indexChange > 0;
          this.hasMoreNext = i + indexChange < element.items.length - 1;

          setTimeout(() => _this.$refs.productionModal.showModal(), 100);
          return;
        }
      }

      for (const element of this.filteredPlanningItems) {
        let i = element.items.findIndex(
          (e) =>
            e.item_id === id &&
            e.warehouse_operation_id === warehouseOperationId
        );
        if (i > -1) {
          this.selectedProduct = element.items[i + indexChange];
          this.hasMorePrev = i + indexChange > 0;
          this.hasMoreNext = i + indexChange < element.items.length - 1;

          setTimeout(() => _this.$refs.productionModal.showModal(), 100);
          return;
        }
      }
    },
    formatDate(val) {
      return date.format(val, true, false);
    },
    filterItems(items) {
      if (this.q != null && this.q.length > 1) {
        let q = this.q.toLowerCase();

        items = items.filter(
          (e) =>
            e.name.toLowerCase().indexOf(q) > -1 ||
            e.serial.toLowerCase().indexOf(q) > -1 ||
            e.warehouse_operation_id == q ||
            e.sku.indexOf(q) > -1
        );
      }
      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            let arr = Object.values(this.filters[filter].value);
            items = items.filter((e) => arr.indexOf(e[filter].toString()) > -1);
          } else {
            items = items.filter(
              (e) => this.filters[filter].value === e[filter]
            );
          }
        }
      }

      return items;
    },
    loadData() {
      this.products = undefined;
      this.productsLoading = true;
      if (this.selectedWarehouse?.type !== 2) {
        return;
      }
      http.fetch("/production/products").then((data) => {
        if (this.filterPage !== "designer") {
          http
            .fetch("/products/products/characteristics?language=en")
            .then((data) => {
              let filterOptions = [];

              for (const obj of data) {
                let max =
                  obj.characteristics_values_max_val_double !== null
                    ? obj.characteristics_values_max_val_double
                    : obj.characteristics_values_max_val_int;
                filterOptions.push({
                  name: obj.name,
                  type: obj.type,
                  key: "caracteristics_key_" + obj.id,
                  value: obj.type === "number" ? [0, max] : {},
                  max: max,
                  values: obj?.characteristics_values,
                  prefix: obj?.prefix,
                  postfix: obj?.postfix,
                });
              }

              this.$store.commit("setFilterPage", "designer");
              this.$store.commit("setFilterOptions", filterOptions);
              this.$store.commit("setFilterOptionsStock", false);
              this.$store.commit("setFilterOptionsPrice", false);
            });
        }

        this.maxSteps = data.maxSteps;
        this.exportLineOptions = data.export.line_options;

        for (const category of data.categories) {
          if (category.manufacturing_material_type !== 1) {
            if (this.steps.indexOf(category.manufacturing_material_step) < 0) {
              this.steps.push(category.manufacturing_material_step);
            }
          } else {
            if (
              this.insideSteps.indexOf(category.manufacturing_material_step) < 0
            ) {
              this.insideSteps.push(category.manufacturing_material_step);
            }
          }
        }
        this.products = data;
        this.productsById = {};
        for (const key of ["planning", "ready", "waiting", "production"]) {
          let sumTime = 0;
          let sumItemCount = 0;
          for (const key2 in this.products[key]) {
            if (this.products[key][key2]?.items !== undefined) {
              sumTime = 0;
              sumItemCount = 0;
              for (const key3 in this.products[key][key2]?.items) {
                sumTime += this.products[key][key2].items[key3].time_left;
                sumItemCount += this.products[key][key2].items[key3].quantity;
                this.productsById[this.products[key][key2].items[key3].id] =
                  this.products[key][key2].items[key3];
                this.products[key][key2].items[key3].locked =
                  (this.products[key][key2].items[key3].lock_count ?? 0) >=
                  data.categories.length;
                if (
                  this.products[key][key2].items[key3].predicted_deadline !==
                    null &&
                  this.products[key][key2].items[key3].deadline_date !== null &&
                  new Date(
                    this.products[key][key2].items[key3].predicted_deadline
                  ).getTime() <
                    new Date(
                      this.products[key][key2].items[key3].deadline_date
                    ).getTime()
                ) {
                  this.products[key][key2].items[key3].emptyLine = true;
                }

                this.products[key][key2].items[key3].print = {
                  item_id: this.products[key][key2].items[key3].item_id,
                  warehouse_operation_id:
                    this.products[key][key2].items[key3].warehouse_operation_id,
                  variant_id: this.products[key][key2].items[key3].variant_id,
                  product_id: this.products[key][key2].items[key3].product_id,
                };

                if (
                  this.products[key][key2].items[key3].image !== null &&
                  this.products[key][key2].items[key3].image !== undefined
                ) {
                  this.products[key][key2].items[key3].image =
                    this.mediaServer +
                    "/" +
                    this.products[key][key2].items[key3].image;
                }
              }
              this.totalTimes[key + "_" + this.products[key][key2]?.id] =
                sumTime;
              this.totalItemCount[key + "_" + this.products[key][key2]?.id] =
                sumItemCount;
            } else {
              sumTime += this.products[key][key2].time_left;
              sumItemCount += this.products[key][key2].quantity;
              this.productsById[this.products[key][key2].id] =
                this.products[key][key2];

              this.products[key][key2].locked =
                (this.products[key][key2].lock_count ?? 0) >=
                data.categories.length;

              if (
                this.products[key][key2].predicted_deadline !== null &&
                this.products[key][key2].deadline_date !== null &&
                new Date(
                  this.products[key][key2].predicted_deadline
                ).getTime() <
                  new Date(this.products[key][key2].deadline_date).getTime()
              ) {
                this.products[key][key2].emptyLine = true;
              }

              if (
                this.products[key][key2].image !== null &&
                this.products[key][key2].image !== undefined
              ) {
                this.products[key][key2].image =
                  this.mediaServer + "/" + this.products[key][key2].image;
              }
            }
          }
          this.totalTimes[key] = sumTime;
          this.totalItemCount[key] = sumItemCount;
        }
        this.productsLoading = false;
      });
    },
    startProduction(id) {
      this.setActiveBtn = true;

      let data = {
        restart: true,
      };

      http
        .fetch("/warehouses/outgoing/" + id + "/start-production", data, true)
        .then(() => {
          this.loadData();
          this.setActiveBtn = false;
        })
        .catch(() => {
          this.setActiveBtn = false;
        });
    },
    setActiveItem(e, i) {
      http
        .fetch(
          "/warehouses/outgoing/" +
            e.warehouse_operation_id +
            "/start-production",
          {
            item_id: e.item_id,
          },
          true
        )
        .then(() => {
          let j = this.products.planning[i].items.findIndex(
            (f) => e.id === f.id
          );
          this.products.planning[i].items.splice(j, 1);
          this.products.waiting[e.manufacturing_material_step ?? 0].items.push(
            e
          );
        });
    },
    startItemProduction(e) {
      http
        .fetch(
          "/production/start-production",
          {
            id: e.id,
          },
          true
        )
        .then(() => {
          let i = this.products.ready.findIndex((f) => e.id === f.id);
          this.products.ready.splice(i, 1);
        });
    },
    valUpdated(item, row, val) {
      if (row === "deadline_date") {
        http.fetch(
          "/warehouses/outgoing/" + item.warehouse_operation_id + "/deadline",
          { item: item.item_id, deadline_date: val },
          false
        );
      }
    },
    formatTime(value) {
      const minutes = Math.floor(value);
      const secs = Math.floor((value - minutes) * 60);

      return `${minutes
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    },
    deleteItem(e) {
      this.removeItem = e;
      this.$refs.removeProductionModal.showModal();
    },
    removeItemFromPoruction() {
      this.removeBtn = true;
      http.fetch("/production/remove", { id: this.removeItem.id }).then(() => {
        this.$refs.removeProductionModal.hideModal();
        this.removeItem = null;
        this.loadData();
        this.removeBtn = false;
      });
    },
  },
};
</script>
